<script lang="ts">
import BurnButton from '../components/list/declarations/BurnButton'
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'

export default {
    name: 'ListDeclarations',
    extends: tablePageInit,
    setup() {
        useHead({
            title: 'Déclarations',
            meta: [
                { name: 'description', content: 'Gestion des déclarations' },
            ],
        })
        definePageMeta({
            middleware: ['auth', 'has-territory'],
        })
    },
    data() {
        return {
            hasMap: false,
            interval: null,
            tabs: useState('declarationTab', () => 'liste'),

            permissionName: 'declaration',
            requestName: 'declarations',
            defaultUrl: '/declarations',
            translationName: 'declarations',
            valueAsViewLink: ['id'],
            columns: [
                { dataKey: 'id', template: [this.showCalculating] },
                { dataKey: 'number', permission: 'declaration_list_show_number_column' },
                { dataKey: 'credentials.created_at', transform: [this.isDate], template: [this.showSeason] },
                { dataKey: 'declaration_type.name', permission: 'declaration_list_show_type_column' },
                { dataKey: 'status', transform: [this.toTranslate], template: [this.isChips, this.showCrossings] },
                { dataKey: 'burn_status', component: BurnButton },
                { dataKey: 'declarant.name', template: [this.showPhoneNumber] },
                { dataKey: 'commune.name', template: [this.isChips] },
                { dataKey: 'called_place' },
                { dataKey: 'passing_cle', transform: [this.isBoolean], template: [this.showCleName] },
                { dataKey: 'surface' },
                { dataKey: 'bd', transform: [this.isBoolean], permission: 'declaration_list_show_bd_column' },
                { dataKey: 'derogation', transform: [this.isBoolean], permission: 'declaration_list_show_derogation_column' },
            ],
            initSort: {
                // key : "name",
                // order : "asc"
            },
            filtersOverload: ['id', 'number', 'seasons.id', 'declarants.name', 'communes.id', 'declaration_types.id', 'where_cle', 'passing_cle', 'status', 'burn_status', 'created_by_me', 'derogation', 'bd'],
            moreFiltersOverload: ['where_checked_by_org_type', 'where_checked_value', 'where_treatment_by', 'where_treatment_required'],
        }
    },
    // unmounted() {
    //     if (this.interval) {
    //         clearInterval(this.interval)
    //     }
    // },
    methods: {
        showSeason(key, value, template, item) {
            let html = template
            if (item.season.name) {
                html += ` <br/>
                        <v-chip size="x-small" class="mb-1" >
                            ${item.season.name}
                        </v-chip>
                    `
            }
            return html
        },
        showCleName(key, value, template, item) {
            let html = template
            if (item.cle?.name) {
                html += `<br>
                <v-tooltip text="Date de la CLE : ${this.$formatDate(item.cle.date_cle)}">
                    <template v-slot:activator="{ props }">
                        <v-chip v-bind="props" size="x-small">${item.cle.name}</v-chip>
                    </template>
                </v-tooltip>`
            }

            return html
        },
        showCalculating(key, value, template, item) {
            let html = template
            if (item.calculating) { // this.$currentUser() && this.$currentUser().role !== 'declarant' &&
                html += ` <br/>
                        <v-chip size="x-small" class="my-1" color="warning">
                            <v-progress-circular indeterminate class="mr-2" size="15" width="2"></v-progress-circular> Calcul en cours
                        </v-chip>
                    `
            }
            return html
        },

        showPhoneNumber(key, value, template, item) {
            let html = template
            if (item.declarant?.mobile) {
                html += `<div class='text-caption'><v-icon class="mr-2" size="small">mdi-phone</v-icon>${item.declarant.mobile}</div>`
            }
            return html
        },
        // showBurnStatus(key, value, template, item) {
        //     let html = `<div class="ma-1">${template}</div>`
        //     if (item.burn_status) {
        //         html += `<v-chip class="mx-1 mb-1" variant="${item.burn_status === 'ongoing' ? 'flat' : 'tonal'}" size="small" prepend-icon="mdi-fire" color="${this.$getColor(`${this.translationName}.burn_status.${item.burn_status}`)}">
        //                         ${this.$translate(`${this.translationName}.burn_status.${item.burn_status}`)}
        //                     </v-chip>`
        //     }
        //     return html
        // },
        showCrossings(key, value, template, item) {
            let html = template
            if (item.crossing_forest) {
                html += `<br/><v-chip class="mx-1 mb-1" size="x-small" variant="flat" color="${this.$getColor(`${this.translationName}.crossing_forest`)}">
                                <b>Croisement forêt</b>
                            </v-chip>`
            }
            if (item.onf_required) {
                html += `<br/><v-chip class="mx-1 mb-1" size="x-small" variant="flat" color="${this.$getColor(`${this.translationName}.onf_required`)}">
                                <b>Croisement forêt RF</b>
                            </v-chip>`
            }
            if (item.crossing_zsm && this.$permissionCheck('map_zsm_index')) {
                html += `<br/><v-chip class="mx-1 mb-1" size="x-small" variant="flat" color="${this.$getColor(`${this.translationName}.crossing_zsm`)}">
                                <b>Croisement ZSM</b>
                            </v-chip>`
            }

            return html
        },
        // lastEdit(key, value, template, item) {
        //     let html = template
        //     if (item.credentials.updated_at) {
        //         html += ` <br/>
        //                 <v-chip size="x-small" class="mb-1">
        //                     Modifiée le ${this.$formatDate(item.credentials.updated_at)}
        //                 </v-chip>
        //             `
        //     }
        //     return html
        // }
    },
}
</script>

<template>
    <div id="declarations">
        <div class="mt-6 mb-3 d-print-none">
            <FormDeclarationAddButton />
            <ListActionsButtonsExport
                :query="$refs?.filters?.query"
                :request-name="requestName"
                :translation-name="translationName"
                :exportable="exportable"
            />
        </div>

        <ListFilters
            v-if="filters"
            ref="filters"
            :permission-name
            :filters
            :loading
            :translation-name
            :filters-overload
            :more-filters-overload
            @load-data="loadData()"
            @reset-pagination="$refs?.pagination.resetPagination()"
        />

        <ListDeclarationsTabs ref="tabs" :tabs="tabs" @update-tab="tabs = $event" />

        <v-card>
            <v-window
                v-model="tabs"
                disabled
            >
                <v-window-item value="liste" class="pa-5">
                    <div class="mb-4">
                        <!-- TO DO : BESOIN DE REINITIALISER LA SELECTEDDATA AU RECHARGEMENT DES FILTRES -->
                        <ListDeclarationsCopyButton
                            :disabled="loading || !(meta?.request?.filter?.created_by_me === 'true' || $currentUser().role === 'admin')"
                            :selected-data="$refs.table?.selectedData || []"
                            :translation-name
                            @load-data="loadData()"
                        />
                        <ListDeclarationsPreconisationButton
                            :disabled="loading || meta?.request?.filter?.created_by_me === 'true'"
                            :selected-data="$refs.table?.selectedData || []"
                            :translation-name
                            @load-data="$refs.table?.selectAll(); loadData()"
                        />
                    </div>

                    <ListTable
                        ref="table"
                        :is-selectable="true"
                        :permission-skip="true"
                        :value-as-view-link="valueAsViewLink"
                        :permission-name="permissionName"
                        :translation-name="translationName"
                        :columns="columns"
                        :sortable="sortable"
                        :default-url="defaultUrl"
                        :loading="loading"
                        :data="data"
                        :init-sort="initSort"
                        @reset-pagination="$refs.pagination.resetPagination()"
                        @load-data="loadData()"
                        @delete-data="deleteData($event)"
                        @update-row="data = data.map(row => row.k === $event.k ? $event : row)"
                    >
                        <template #optionsList="{ item }">
                            <v-list-item
                                v-if="$permissionCheck(`${permissionName}_show`) && !item.deleted"
                                density="compact"
                                class="link-as-list-item"
                            >
                                <v-list-item-title v-if="!item.pdf_downloadable" class="text-caption text-medium-emphasis">
                                    <v-progress-circular
                                        class="mr-2"
                                        color="primary"
                                        indeterminate
                                        size="14"
                                        width="2"
                                    />
                                    Initialisation du PDF
                                </v-list-item-title>
                                <DocumentsDownloadLink
                                    v-else
                                    ref="downloadPdf"
                                    :filename="`${item.k}.pdf`"
                                    :url="`${requestName}/${item.k}?format=pdf`"
                                >
                                    <v-list-item-title class="text-caption">
                                        <v-progress-circular
                                            v-if="$refs.downloadPdf?.loading"
                                            class="mr-2"
                                            color="primary"
                                            indeterminate
                                            size="14"
                                            width="2"
                                        />
                                        <v-icon
                                            v-else
                                            icon="mdi-file-pdf-box"
                                            size="small"
                                            class="mr-2"
                                            color="dark"
                                        />
                                        {{ $translate(`${translationName}.pdf`) }}
                                    </v-list-item-title>
                                </DocumentsDownloadLink>
                            </v-list-item>
                        </template>
                    </ListTable>
                    <div>
                        <ListPagination ref="pagination" :total :loading :last-page @load-data="loadData" />
                    </div>
                </v-window-item>
                <v-window-item value="carte" class="pa-5">
                    <ListDeclarationsMap :translation-name :loading :meta :filters="$refs.filters" :request-name />
                </v-window-item>
            </v-window>
        </v-card>

        <NuxtPage
            :request-name :default-url
            :translation-name :permission-name
            @load-data="loadData()"
            @delete-data="deleteData($event)"
        />
    </div>
</template>

<style type="text/css" media="print">
  @page {
    size: landscape;
}
</style>
